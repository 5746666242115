<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    fullscreen
  >
    <div class="bg-white poppins position-relative min-h-100">
      <div
        class="w-100 d-flex py-3 px-6 align-center justify-content-between bg-white position-sticky top-0 left-0 zindex-5"
      >
        <h4 class="mb-0 font-weight-bolder poppins">GRN History</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="mx-5 py-7" v-if="isDataLoaded">
        <!--begin::Body-->
        <HistoryDataTable
          ref="historyDataTable"
          :table_state="table_state"
          :store_names="store_names"
        />
        <!--end::Body-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import HistoryDataTable from "@/own/components/warehouseManagement/standardGrn/history/HistoryDataTable.vue";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/standardGrnHistoryDatatable.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "HistoryPopup",
  components: { HistoryDataTable },
  data: () => ({
    dialog: false,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getSTANDARDGRNHISTORYTableFilters",
      getTableState: "getSTANDARDGRNHISTORYTableState",
      getTableData: "getSTANDARDGRNHISTORYTableData",
      getTableHeaders: "getSTANDARDGRNHISTORYTableHeaders",
      getTableProperties: "getSTANDARDGRNHISTORYTableProperties",
      getTableExportUrl: "getSTANDARDGRNHISTORYExportUrl",
      getTablePermissions: "getSTANDARDGRNHISTORYTablePermissions",
    },
  }),
  methods: {
    toggleModal(itemId) {
      if (this.dialog) {
        this.dialog = !this.dialog;
      } else {
        this.dialog = true;
        setTimeout(() => {
          // this.$refs.historyDataTable.initTableContent(itemId);
          this.initTableContent(itemId);
        }, 500);
      }
    },
    async initTableContent(asn_id) {
      this.$store.commit(SET_PAGE_LOADING, true);
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store
        .dispatch(this.table_state.UPDATE_TABLE_DATA, {
          asn_id,
          warehouse_id: this.$store.getters.getSelectedWarehouse,
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
};
</script>
