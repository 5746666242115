<template>
  <div>
    <v-menu left transition="scale-transition" offset-y bottom rounded>
      <template v-slot:activator="{ on, attrs }">
        <a
          v-bind="attrs"
          v-on="on"
          class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
        >
          Actions
          <span class="svg-icon svg-icon-5 m-0">
            <v-icon small>mdi-chevron-down</v-icon>
          </span>
        </a>
      </template>
      <v-card class="poppins py-1" elevation="1">
        <div v-if="permissionChecker('create_grn')">
          <v-btn
            class="w-100 text-left"
            :disabled="!item.extra_data.detail.actions.generate"
            :class="
              !item.extra_data.detail.actions.generate
                ? 'text-muted'
                : 'text-dark'
            "
            @click="generate(item.id)"
            text
            >Generate</v-btn
          >
        </div>
        <div v-if="permissionChecker('create_grn')">
          <v-btn
            class="w-100 text-left"
            :disabled="!item.extra_data.detail.actions.history"
            :class="
              !item.extra_data.detail.actions.history
                ? 'text-muted'
                : 'text-dark'
            "
            @click="handleHistory(item.id)"
            text
            >History</v-btn
          >
        </div>
      </v-card>
    </v-menu>

    <CreateGrnPopup ref="createGrnPopup" />
    <HistoryPopup ref="historyPopup" />
  </div>
</template>

<script>
import CreateGrnPopup from "@/own/components/warehouseManagement/standardGrn/CreateGrnPopup.vue";
import HistoryPopup from "@/own/components/warehouseManagement/standardGrn/history/HistoryPopup.vue";

export default {
  name: "StandardGrnDataTableActionSelector",
  components: { CreateGrnPopup, HistoryPopup },
  props: ["item", "createGrnPopupToggleModal"],
  methods: {
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
    generate(id) {
      this.$refs.createGrnPopup.toggleModal(id);
    },
    handleHistory(id) {
      this.$refs.historyPopup.toggleModal(id);
    },
  },
  computed: {
    table_permissions: function () {
      if (this.$store.getters.getSTANDARDGRNTablePermissions) {
        return this.$store.getters.getSTANDARDGRNTablePermissions.map(function (
          perm
        ) {
          return perm.name;
        });
      } else {
        return false;
      }
    },
  },
};
</script>
